<!-- eslint-disable no-debugger -->
<!-- eslint-disable no-debugger -->
<!-- eslint-disable no-debugger -->
<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Company Details</div>
    <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <button type="button" class="header-btn-outer btn btn-primary" @click="addCompanyModalOpen()">
        Add Company
      </button>
    </div>
  </div>

  <div class="company-detail-box-outer" style="height: calc(100vh - 141px)">
    <div v-if="!showdataloader">
      <div class="company-box-outer" v-for="(company, index) in companyList" v-bind:key="index">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="d-flex justify-content-between">
              <div class="customer-label-group">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <span v-if="company.al4 != null && company.al4 != ''">
                      <img :src="company.al4" class="myprofile-avatar" alt="company profile image" width="60"
                        height="60" />
                    </span>

                    <span v-if="company.al4 == null || company.al4 == ''">
                      <img src="/assets/images/company-logo.svg" class="myprofile-avatar rounded-circle"
                        alt="company profile image" width="60" height="60" />
                    </span>
                  </div>
                  <div class="
                        flex-grow-1
                        ms-3
                        company-legal-name
                        text-capitalize
                      ">
                    {{ company.al3 ? company.al3 : "N/A" }}
                  </div>
                </div>
              </div>
              <div class="customer-label-group d-flex align-items-center">
                <div class="me-4">
                  <label class="form-label me-3">Billing Status : </label>
                  <InputSwitch v-model="company.al14" :true-value="dynamicTrueActiveValue"
                    :false-value="dynamicFalseInactiveValue" @change="changeCompanyStatus(company)" />
                  <!-- <span v-if="company.al15 == 1" class="status-active ms-2">Active</span>
                  <span v-if="company.al15 == 0" class="status-inactive ms-2">Inactive</span> -->
                </div>
                <button type="button" @click="editCompanyDetailsModalOpen(company)" class="btn custom-view-detail-btn">
                  Edit Details
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="customer-label-group">
              <label class="form-label">Legal Name</label>
              <div class="from-label-value text-capitalize">
                {{ company.al3 ? company.al3 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="customer-label-group">
              <label class="form-label">Company Type</label>
              <div class="from-label-value text-capitalize">
                {{ company.al9 ? company.al9 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="customer-label-group">
              <label class="form-label">GST No.</label>
              <div class="from-label-value text-uppercase">
                {{ company.al6 ? company.al6 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="customer-label-group">
              <label class="form-label">PAN No.</label>
              <div class="from-label-value text-uppercase">
                {{ company.al5 ? company.al5 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-12">
            <div class="customer-label-group">
              <label class="form-label">Area</label>
              <div class="from-label-value text-capitalize">
                {{ company.al12 ? company.al12 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="customer-label-group">
              <label class="form-label">Address</label>
              <div class="from-label-value">
                {{ company.al7 ? company.al7 : "N/A" }}
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="customer-label-group">
              <label class="form-label">Pincode</label>
              <div class="from-label-value text-capitalize">
                {{ company.al13 ? company.al13 : "N/A" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company-box-outer" v-if="companyList == ''">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="pb-3">No records found.</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showdataloader" style="height: 430px">
      <div class="custom-modal-spinner-loader">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
    </div>
  </div>
  <!-- Add Company modal start here -->
  <div class="modal-mask" v-if="addcompanymodalstatus">
    <div class="
        modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ !isEditCompanyDetails ? 'Add Company Details' : 'Edit Company Details' }}
          </h5>
          <button type="button" class="btn-close" @click="addcompanymodalstatus = false"></button>
        </div>
        <div class="modal-body">
          <div class="company-modal-fixed-height">
            <div class="row" v-if="!showaddmodalloader">
              <div class="col-12">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Marketing Name<span class="text-danger">*</span></label>
                      <input type="text" :disabled="showloader" v-model="add.marketingname"
                        class="form-control text-capitalize" placeholder="Enter Marketing Name" autocomplete="off" />
                      <div class="custom-error" v-if="v$.add.marketingname.$error">
                        {{ v$.add.marketingname.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Legal Name<span class="text-danger">*</span></label>
                      <input type="text" :disabled="showloader" v-model="add.legalname"
                        class="form-control text-capitalize" placeholder="Enter Legal Name" autocomplete="off" />
                      <div class="custom-error" v-if="v$.add.legalname.$error">
                        {{ v$.add.legalname.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Company Type<span class="text-danger">*</span></label>
                      <Multiselect v-model="add.companytype" :disabled="showloader" :options="companytypelist"
                        :searchable="true" label="label" placeholder="Select Company Type"
                        class="multiselect-custom text-capitalize" :canClear="true" :closeOnSelect="true"
                        noOptionsText="No Result Found" :object="true" />
                      <div class="custom-error" v-if="v$.add.companytype.$error">
                        {{ v$.add.companytype.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">GST No.<span class="text-danger">*</span></label>
                      <input type="text" v-model="add.gstnumber" :disabled="showloader"
                        @input="validateAddGstNumber($event)" class="form-control text-uppercase"
                        placeholder="Enter GST No." maxlength="15" autocomplete="off" />
                      <div class="custom-error" v-if="this.errmsg.erroraddgstnumber">{{
                        this.errmsg.erroraddgstnumber
                      }}</div>
                      <div class="custom-error" v-if="v$.add.gstnumber.$error">
                        {{ v$.add.gstnumber.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">PAN No.<span class="text-danger">*</span></label>
                      <input type="text" v-model="add.pannumber" disabled class="form-control text-uppercase"
                        placeholder="Enter PAN No." @input="validateAddPanCardNumber($event)" maxlength="10"
                        autocomplete="off" />
                      <div class="custom-error" v-if="this.errmsg.erroraddpannumber">{{
                        this.errmsg.erroraddpannumber
                      }}</div>
                      <div class="custom-error" v-if="v$.add.pannumber.$error">
                        {{ v$.add.pannumber.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Area<span class="text-danger">*</span></label>
                      <input type="text" v-model="add.area" :disabled="showloader" class="form-control text-capitalize"
                        placeholder="Enter Area Name" autocomplete="off" />
                      <div class="custom-error" v-if="v$.add.area.$error">
                        {{ v$.add.area.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Address<span class="text-danger">*</span></label>
                      <textarea type="text" v-model="add.address" :disabled="showloader"
                        class="form-control custom-text-area-scroll" placeholder="Enter Address" autocomplete="off"
                        rows="2" maxlength="250"></textarea>
                      <div class="custom-error" v-if="v$.add.address.$error">
                        {{ v$.add.address.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Pincode<span class="text-danger">*</span></label>
                      <input type="text" v-model="add.pincode" :disabled="showloader" id="addpincodeInput"
                        class="form-control text-capitalize" placeholder="Enter Pincode" maxlength="6"
                        @keypress="onlypincodeAddFormat" autocomplete="off" />
                      <div class="custom-error" v-if="v$.add.pincode.$error">
                        {{ v$.add.pincode.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="row">
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="custom-form-group">
                          <label class="form-label">Upload Company Logo</label>
                          <FileUpload mode="basic" ref="companylogoimgadd" :disabled="showloader"
                            class="custom-upload-btn-ultima" v-model="add.companylogoimg" accept="image/*"
                            v-on:change="handleUploadCompanyLogoAdd()" />
                          <div class="custom-error" v-if="errorcompanylogoadd">{{ errorcompanylogoadd }}</div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12" v-if="createpreviewurl != null && createpreviewurl != ''">
                        <img v-if="rowId != ''"  :src="createpreviewurl" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                        <img :src="createpreviewurl.objectURL" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    voter-photo-img
                                  " alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="custom-form-group">
                      <label class="form-label">Status<span class="text-danger">*</span></label>
                      <div class="custom-group-radio-box-btn">
                        <input type="radio" class="btn-check" v-model="add.companystatus" name="company-companystatus"
                          id="active" autocomplete="off" value="1" />
                        <label class="btn custom-radio-box-btn text-capitalize" for="active">Active</label>
                        <input type="radio" class="btn-check" v-model="add.companystatus" name="gender-option"
                          id="inactive" autocomplete="off" value="0" />
                        <label class="btn custom-radio-box-btn text-capitalize" for="inactive">Inactive</label>
                      </div>
                      <div class="custom-error" v-if="v$.add.companystatus.$error">
                        {{ v$.add.companystatus.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showaddmodalloader">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" @click="addOrUpdateCompanyDetail()" class="btn modal-bulk-next-btn"
              :disabled="showloader">
              <span v-if="!showloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Company modal end here -->
</template>
<script>
import { required, helpers, minLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../../service/ApiService";
export default {
  data() {
    return {
      v$: useValidate(),
      addcompanymodalstatus: false,
      editcompanymodalstatus: false,
      companyDetail: "",
      companyList: [],
      showdataloader: false,
      showaddmodalloader: false,
      showmodalloader: false,
      rowId: '',
      companytypelist: [],
      errmsg: {},
      showloader: false,
      errpan: "",
      errStatus: false,
      errgst: "",
      errorcompanylogoadd: '',
      errorcompanylogoedit: '',
      modalid: '',
      errors: "",
      isEditCompanyDetails: false,
      add: {
        marketingname: "",
        legalname: "",
        area: "",
        pincode: "",
        address: "",
        gstnumber: "",
        pannumber: "",
        companystatus: 1,
        companytype: null,
        companytypelist: [],
        companylogoimg: null,
      },
      createpreviewurl: null,
      exitpreviewurl: null,
      switchuncheck: false,
      dynamicTrueActiveValue: 1,
      dynamicFalseInactiveValue: 0,
      gstnumberexistList: null,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.getcompanytypes();
    this.getAdminCompanyList();
  },
  validations() {
    return {
      add: {
        marketingname: {
          required: helpers.withMessage("Please enter marketing name", required),
        },
        legalname: {
          required: helpers.withMessage("Please enter legal name", required),
        },
        area: {
          required: helpers.withMessage("Please enter area name", required),
        },
        pincode: {
          required: helpers.withMessage("Please enter pincode", required),
          minLength: helpers.withMessage(
            "Pincode should be min 6 length",
            minLength(6)
          ),
        },
        companytype: {
          required: helpers.withMessage("Please select company type", required),
        },
        gstnumber: {
          required: helpers.withMessage("Please enter GST number", required),
        },
        pannumber: {
          required: helpers.withMessage("Please enter PAN number", required),
        },
        address: {
          required: helpers.withMessage("Please enter address", required),
        },
        companystatus: {
          required: helpers.withMessage("Please select status", required),
        },
      },
    };
  },
  methods: {
    getAdminCompanyList(e) {
      this.showdataloader = true;
      this.ApiService.getAdminCompanyList(e).then((data) => {
        if (data.status == 200) {
          this.companyList = data.data;
          this.gstnumberexistList = data.data.al6;
          this.showdataloader = false;
        } else {
          this.companyList = "";
          this.showdataloader = false;
        }
      });
    },
    getcompanytypes(e) {
      this.ApiService.getcompanytypes(e).then((data) => {
        if (data.status == 200) {
          this.companytypelist = data.data;
        }
      });
    },
    changeCompanyStatus(data) {
      let fields = {};
      fields["al1"] = data.al1;
      if (data.al14 == 0 || data.al14 == false) {
        fields["al14"] = 0;
      }
      if (data.al14 == 1 || data.al14 == true) {
        fields["al14"] = 1;
      }
      this.ApiService.changeAdminBillingCompanyStatus(fields).then((items) => {
        if (items.status === 200) {
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.getAdminCompanyList();
        } else {
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    addCompanyModalOpen() {
      this.addcompanymodalstatus = true;
      this.showaddmodalloader = false;
      this.add.marketingname = "";
      this.add.legalname = "";
      this.add.area = "";
      this.add.pincode = "";
      this.add.address = "";
      this.add.gstnumber = "";
      this.add.pannumber = "";
      this.add.companystatus = 1;
      this.add.companytype = '';
      this.add.companylogoimg = null;
      this.createpreviewurl = null;
    },
    addOrUpdateCompanyDetail() {
      this.v$.add.$validate();
      var formData = new FormData();
      if (this.rowId) {
        formData.append("al1", this.rowId);
      }
      formData.append("al2", this.add.marketingname);
      formData.append("al3", this.add.legalname);
      formData.append("al5", this.add.pannumber);
      formData.append("al6", this.add.gstnumber);
      formData.append("al7", this.add.address);
      formData.append("al9", this.add.companytype.label);
      formData.append("al8", this.add.companytype.value);
      formData.append("al12", this.add.area);
      formData.append("al13", this.add.pincode);
      formData.append("al15", this.add.companystatus);
      if (this.add.companylogoimg) {
        formData.append("al4", this.add.companylogoimg);
      }
      if (!this.v$.add.$error && this.errStatus == false && !this.errorcompanylogoadd) {
        this.showloader = true;
        this.ApiService.addorUpdateAdminCompany(formData).then((data) => {
          if (data.status == 200) {
            this.showloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.addcompanymodalstatus = false;
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.add.marketingname = '';
            this.add.legalname = '';
            this.add.pannumber = '';
            this.add.gstnumber = '';
            this.add.address = '';
            this.add.companytype = '';
            this.add.area = '';
            this.add.pincode = '';
            this.add.companystatus = 1;
            this.add.companylogoimg = null;
            this.rowId = '';
            this.getAdminCompanyList();
          } else {
            this.showloader = false;
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      } else {
        this.$error;
      }
    },
    editCompanyDetailsModalOpen(e) {
      this.addcompanymodalstatus = true;
      this.showaddmodalloader = false;
      this.isEditCompanyDetails = true;
      this.rowId = e.al1;
      this.add.marketingname = e.al12;
      this.add.legalname = e.al3;
      this.add.pannumber = e.al5;
      this.add.gstnumber = e.al6;
      this.add.address = e.al7;
      this.add.companytype = { label: e.al9, value: e.al8 };
      this.add.area = e.al12;
      this.add.pincode = e.al13;
      this.add.companystatus = e.al15;
      this.createpreviewurl = e.al4;
      this.add.companylogoimg = this.createpreviewurl;
      this.showmodalloader = false;
    },

    handleUploadCompanyLogoAdd() {
      var file = this.$refs.companylogoimgadd.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!file.size > 2048) {
        this.errorcompanylogoadd = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorcompanylogoadd = 'Invalid file type';
      } else {
        this.errorcompanylogoadd = '';
        this.add.companylogoimg = this.$refs.companylogoimgadd.files[0];
        this.createpreviewurl = this.add.companylogoimg;
      }
    },
    validateAddPanCardNumber(e) {
      var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
      let add_pan_number = e.target.value.toUpperCase();
      this.erroraddpannumber = add_pan_number;
      if (add_pan_number && !add_pan_number.match(regex)) {
        this.errmsg["erroraddpannumber"] = "Please enter valid pan number";
        this.errStatus = true;
      } else {
        this.errmsg["erroraddpannumber"] = "";
        this.errStatus = false;
      }
    },

    validateAddGstNumber(e) {
      var regexGst = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
      let add_gst_number = e.target.value.toUpperCase();
      this.erroraddgstnumber = add_gst_number;
      if (add_gst_number && !add_gst_number.match(regexGst)) {
        this.errmsg["erroraddgstnumber"] = "Please enter valid GST number";
        this.errStatus = true;
      } else {
        this.errmsg["erroraddgstnumber"] = "";
        this.errStatus = false;
        this.add.pannumber = add_gst_number.substr(2, 10);
      }
      if (add_gst_number.length == 15) {
        const isGstExist = this.companyList.some((d) => d.al6 == add_gst_number);
        if (isGstExist === true) {
          this.errmsg["erroraddgstnumber"] = "GST no. is already exist.";
          this.errStatus = true;
        }
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },

    onlypincodeAddFormat($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
      var addpincodeInput = document.getElementById("addpincodeInput");
      if ((addpincodeInput.value == '' || addpincodeInput.length > 1) && (keyCode == 48)) {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}

.company-detail-box-outer .company-profile-img {
  pointer-events: none;
}

.company-detail-box-outer {
  position: relative;
  height: 525px;
  overflow: auto;
}

.company-box-outer {
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  margin-top: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.company-box-outer:last-child {
  margin-bottom: 20px;
}

.customer-label-group {
  margin-bottom: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.company-detail-box-outer::-webkit-scrollbar {
  display: block;
}

.company-detail-box-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-detail-box-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.status-active {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-inactive {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}
</style>